<template>
    <o-data-lookup :data-object="dsPersonsLkp" :title="$t('Person')">
        <template #toolbarActions>
            <slot name="toolbarActions"></slot>
        </template>
        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.person" :ref="scope.target" style="cursor: pointer;">
                <slot name="person"></slot>
            </span>
        </template>
        <o-column field="Name" width="200"></o-column>
        <o-column field="MobileNo" width="150"></o-column>
        <o-column field="Email" width="200"></o-column>
        <o-column field="Represents" width="150"></o-column>
    </o-data-lookup>
</template>

<script setup>
import { ref, computed, defineProps, watchEffect } from 'vue';
import { getOrCreateDataObject } from 'o365.vue.ts';

const defaultView = "sviw_System_PersonsLookup";

const props = defineProps({
    viewName: {
        type: String,
        required: false,
        default: defaultView,
    },
    initialWhereClause: {
        type: String,
        required: false,
        default: "",
    },
    dataObject: {
        type: Object,
        required: false,
    },
    noRecent: {
        type: Boolean,
        default: false
    },
});

const viewName = computed(() => props.viewName);
const dataObject = computed(() => props.dataObject);
const noRecent = computed(() => props.noRecent);

const dsPersonsLkp = dataObject.value ? dataObject.value : (getOrCreateDataObject({
    id: 'o_dsPersonsLkp',
    viewName: viewName.value, 
    maxRecords: 25,
    whereClause: props.initialWhereClause,
    loadRecents: !noRecent.value,
    fields: [
        { name: "ID", type: "number" },
        { name: "FirstName", type: "string" },
        { name: "LastName", type: "string", sortOrder: 1, sortDirection: "asc" },
        { name: "Name", type: "string" },
        { name: "MobileNo", type: "string" },
        { name: "Represents", type: "string" },
        { name: "Email", type: "string" },
    ]
}));

</script>
